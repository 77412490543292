@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&family=Roboto:wght@100;300;400;700;900&display=swap);
*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body{
    font: 300 15px Roboto, sans-serif;
    background: #0076BF;
    -webkit-font-smoothing: antialiased;
}

input, button, textarea{
    font: 300 18px Roboto, sans-serif;
}

button{
    cursor: pointer;
}

form input{
    margin:0 auto;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 45px;
    border-radius: 6px;
    border: 0px;
    transition: 0.2s;
    padding:0 12px; 
}
form input:hover{
    -webkit-filter: brightness(90%);
            filter: brightness(90%)
}
input::-webkit-input-placeholder{
    padding-left: 4px;
}
input:-ms-input-placeholder{
    padding-left: 4px;
}
input::-ms-input-placeholder{
    padding-left: 4px;
}
input::placeholder{
    padding-left: 4px;
}

form button{
    margin:0 auto;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    width: 80%;
    height: 45px;
    border-radius: 6px;
    border: 0px;
    background: #0076BF;
    color: #ffffff;
    font: 500 18px Roboto, sans-serif;
    text-align: center;
    text-decoration: none;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}
.button:hover{
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}
.link{
    margin-left: 40px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #fff;
    font-size: 15px;
    text-decoration: none;
    font-weight: thin;
    transition: -webkit-filter 0.1s;
    transition: filter 0.1s;
    transition: filter 0.1s, -webkit-filter 0.1s;
}
.link{
    -webkit-filter:brightness(80%);
            filter:brightness(80%);
}
.link svg{
    margin-right: 8px;
}


.logon-container{
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.logon-container section.form{
    margin: 0 auto;
    width: 100%;
    height: 300px;
    max-width: 350px;
    margin-right: 30px;
    background: #004F80;
}
.logo-principal{
    margin: 0 auto;
    margin-left: 100px;
}

.logon-container section.form form{
   margin-top: 50px;
}

.button{
    align-items: center;
    padding: 5px;
}






.register-container{
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.register-container section.form{
    margin: 0 auto;
    width: 100%;
    height: 400px;  
    max-width: 350px;
    margin-right: 30px;
    background: #004F80;
}
.logo-principal{
    margin: 0 auto;
    margin-left: 100px;
}
.register-container section.form form{
    margin-top: 50px;
 }

 button{
    padding-top: 11px;
 }
header{
    width: 100%;
    max-width: 1366px;
    padding:0 30px;
    background: #002740;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
header img{
    margin-top: 10px;
    max-width: 150px;
    margin-bottom: 10px;
    cursor: pointer;
}
header img:hover{
    opacity: 0.9;
}
header span{
    color:#fff;
    font: 100 18px Roboto, sans-serif;
    font-size: 30px;
    margin-left: auto;
    margin-right: 20px;
    
}
header svg{
    background: transparent;
    padding:0px;
    margin:0px;
    transition: 0.1s;
    margin-right: 8px;
}
svg:hover{
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
}

header button{
    margin-top:0;
    background: transparent;
    border-radius:4px;
    margin-left: 10px;
    border:0px;
}
.name{
    font: 300 30px Roboto, sans-serif;
}
.geral{

}
.container-groups{
    display: flex;
}
.title{
    justify-content: space-between;

}
.float-button{
    position: fixed;
    bottom: 80px;
    right:60px;
    color: #f2f2f2;
    background-color: #099630;
    padding: 15px;
    justify-content: space-between;
    border-radius: 30px;
    display: flex;
    font-size: 16px;
    cursor: pointer;
}
.float-button-undo{
    position: fixed;
    bottom: 80px;
    right:60px;
    color: #f2f2f2;
    background-color: #960909;
    padding: 15px;
    justify-content: space-between;
    border-radius: 30px;
    display: flex;
    font-size: 16px;
    cursor: pointer;
}
.float-button svg{
    size: 20px;
    font-size: 20px;
}
.float-button:hover{
    width: 130px;
}
.title button{
    margin-top: 10px;
    margin-right: 11px;
    align-items: center;
    padding: 10px;
    border: 0px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    transition: 0.2s;
    
}
.title button:hover{
    background-color: #099630;
    color: #fff

}
.title svg{
    margin-left: 5px;
    margin-bottom: 5px;
}
.title section svg {
    margin-left: 10px;
    margin-top: 15px;
}


.title  svg:hover{
    color: #fff
}
.title button textarea{
    margin-left: 5px;
    margin-bottom: 5px;
}

.groups-container{
    background:#0076BF;
    width: 50% ;
    height: 100vh;
    left: 0px;
}
.groups-container h1{
    font: 300 30px Roboto, sans-serif;
    color:#fff;
    text-align: left;
    padding: 10px;
    margin-left: 10px;
}
.groups-container ul {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 11px;
    list-style: none;
}

.groups-container ul li{
    background: #fff;
    border-radius: 8px;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    cursor: pointer;
}

.groups-container ul li div{
    border-radius: 10px;
    background: rgba(229, 229, 229, 0.75);
    display: flex;
    margin-bottom: 10px;
    margin-right: auto;
    align-items: center;
    height: 30%;
}

.img-container{
    width: 50% ;
    height: 100vh;
}

.img-container img{
    display: block;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto 
}
.groups-container section{
    margin-left: 10px;
    margin-bottom: 10px;
    display: flex;
}
.groups-container section strong{
    margin-right: 5px;
    color: #002740;
    font: 500 18px Roboto, sans-serif;
    
}
.groups-container section p{
    margin-right: 4px;
    color: #004F80;
    font: 300 18px Roboto, sans-serif;
    
}
.groups-container section svg{
    margin-right: 4px;
    cursor: pointer;
}
.group-header{
    margin-left: 0px;
    justify-content: space-between;
    
}
.group-header h2{
    color: #004F80;
    margin-left: 10px;
}
.group-header svg{
    margin-right: 10px;
    margin-top: 10px;
    background-color:transparent;
    margin-bottom: 10px;
    
}
.groupDetails-container{
    position: fixed;
    bottom: 47px;
    right:50px;
    background-color: #fff;
    border-radius: 8px;
    width: 43vw;
    height: 70vh;

    display: block;
    left: px;
}

.group-details-header{
    margin-left: 0px;
    display: flex;
    background-color: #ECECEC ;
    border-radius: 8px;
}
.group-details-header h2{
    color: #004F80;
    margin-left: 5%;
    margin-top: 10px;
}
.group-details-header section{
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.group-details-header svg{
    margin-left: 5px;
    margin-right: 10px;
    background-color:transparent;
    cursor: pointer;
    
}

.itens{
    margin-left: 10px; 
    
}
.itens section{
    display: flex;
    padding: 8px;
}
.itens section strong{
    margin-right: 4px;
    margin-left: 4px;
    margin-top: 4px;
}
.itens section p{
    margin-right: 4px;
    margin-left: 4px;
    margin-top: 4px;
}

.itens ul{
    margin-left: 15px;
    display: grid;
    grid-template-columns: repeat(4,3fr);
    grid-gap: 3px;
    list-style: none;

}
.itens ul li{
    display: flex;

  
}
.itens ul li p{
    margin-left: 5px;
    justify-content: baseline;
  
}

.modal-container section{
    top: 300px;
}
.modal-container section{
    top: 300px;
}
.container-new-group{
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: center;
}
.form-new-group{
    width: 380px;
    height: 470px;  
    max-width: 600px;
    background: #004F80;
    margin-top: 60px;
    margin-right: 30px;
    background: #004F80;
}

.form-new-group form{
    padding: 20px;
}
.form-new-group form input{
    width: 100%;
    margin-bottom: 10px;
}
.form-new-group form strong{  
    font: 400 17px Roboto, sans-serif;
    color: #fff;
}
.form-new-group form button{
    margin-top:20px;
    width: 100%;
    transition: 0.2s;
}
.form-new-group form button:hover{
    background-color:#002740 ;
}

.form-new-group form div{
    display: flex;
}
.form-new-group form div section{
    margin:4px
}
.form-new-group form div section input{
    width: 100;
}


.myprofile{
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: center;
}
.myprofile SVG{
    margin-right: 100px;

}

.myprofile section {
    width: 380px;
    height: 390px;  
    max-width: 600px;
    background: #004F80;
    margin-top: 60px;
    margin-right: 30px;
    background: #004F80;
}
.myprofile section form{
    padding: 20px;      
}
.myprofile form input{
    width: 100%;
    margin-bottom: 10px;
}
.myprofile form strong{  
    font: 400 17px Roboto, sans-serif;
    color: #fff;
}
.myprofile form button{
    margin-top:25px;
    width: 100%;
    transition: 0.2s;
}
.myprofile form button:hover{
    background-color:#002740 ;
}

.participante-container{
    display: flex;
    align-items: center;
}
.participante-container img{
    margin-top: 50px;
    margin-left: 50px;
}
.participante-container svg{
    margin-top: -275px;

}


.participante{
    background-color: #004F80;
    text-align: center;
    height: 50vh;
    width: 25%;
    align-items: center;
    justify-content: center;
}
.participante input{
    width: 90%;
}
.participante button{
    width: 90%;
    margin-top: 30px;
    
}
.participante h2{
    font: 300 27px Roboto, sans-serif;
    margin-top: 30px;
    margin-bottom: 55px;
    color: #fff;

}



.container-edit-group{
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: center;
}
.container-edit-group svg{
    margin-top: -375px;
}
.form-edit-group{
    width: 380px;
    height: 470px;  
    max-width: 600px;
    background: #004F80;
    margin-top: 60px;
    margin-right: 30px;
    background: #004F80;
}

.form-edit-group form{
    padding: 20px;
}
.form-edit-group form input{
    width: 100%;
    margin-bottom: 10px;
}
.form-edit-group form strong{  
    font: 400 17px Roboto, sans-serif;
    color: #fff;
}
.form-edit-group form button{
    margin-top:20px;
    width: 100%;
    transition: 0.2s;
}
.form-edit-group form button:hover{
    background-color:#002740 ;
}

.form-edit-group form div{
    display: flex;
}
.form-edit-group form div section{
    margin:4px
}
.form-edit-group form div section input{
    width: 100;
}
