@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Roboto:wght@100;300;400;700;900&display=swap');

*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body{
    font: 300 15px Roboto, sans-serif;
    background: #0076BF;
    -webkit-font-smoothing: antialiased;
}

input, button, textarea{
    font: 300 18px Roboto, sans-serif;
}

button{
    cursor: pointer;
}

form input{
    margin:0 auto;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 45px;
    border-radius: 6px;
    border: 0px;
    transition: 0.2s;
    padding:0 12px; 
}
form input:hover{
    filter: brightness(90%)
}
input::placeholder{
    padding-left: 4px;
}

form button{
    margin:0 auto;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    width: 80%;
    height: 45px;
    border-radius: 6px;
    border: 0px;
    background: #0076BF;
    color: #ffffff;
    font: 500 18px Roboto, sans-serif;
    text-align: center;
    text-decoration: none;
    transition: filter 0.2s;
}
.button:hover{
    filter: brightness(90%);
}
.link{
    margin-left: 40px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #fff;
    font-size: 15px;
    text-decoration: none;
    font-weight: thin;
    transition: filter 0.1s;
}
.link{
    filter:brightness(80%);
}
.link svg{
    margin-right: 8px;
}

